<template lang="pug">
  div 
    v-snackbar(v-model="success" rounded="pill" color="accent") 
      v-icon.mr-4(left color="white") mdi-check-bold
      | Passwort wurde erfolgreich geändert
    v-dialog(v-model='dialog' max-width="380" persistent)
        template(v-slot:activator='{ on, attrs }')
            v-btn.px-2.ml-sm-4(v-bind='attrs' v-on='on' dark text color='accent') Passwort ändern
        v-card
          div
            v-toolbar(flat color="transparent")
              v-toolbar-title.text-h6.dialog-title.font-weight-bold Neues Passwort setzen
              v-spacer
              v-btn(icon @click='closeDialog')
                v-icon mdi-close
            v-divider
          v-card-text.pt-6.px-4
            v-alert(type="error" v-if="error") {{error}}
            v-form(v-model='newPasswordIsValid')
                  v-text-field(v-model='currentPassword' dense outlined label='Aktuelles Passwort' type="password")
                  v-text-field(v-model='newPassword' dense outlined :rules='rules.passwordRules' label='Neues Passwort' hint='Mindestens 8 Zeichen' type="password")
                  v-text-field(v-model='newPasswordRepeat' dense outlined :rules='rules.newPasswordRules' label='Neues Passwort wiederholen' type="password")
          v-divider
          v-card-actions.px-4
            v-spacer
            v-btn(color='grey' text @click='closeDialog') Abbrechen
            v-btn.white--text(@click='setNewPassword' color='accent darken-1' depressed :disabled='!newPasswordIsValid') Speichern
        
</template>
<script>
import Vue from 'vue';
import FormRules from '@/utilities/FormRules';
export default Vue.extend({
  data() {
    return {
      rules: {
        ...FormRules,
        newPasswordRules: [
          (v) => !!v || 'Bitte wiederhole das neue Passwort',
          (v) => v === this.newPassword || 'Passwörter müssen übereinstimmen',
        ],
      },
      dialog: false,
      success: false,
      currentPassword: undefined,
      newPassword: undefined,
      newPasswordRepeat: undefined,
      newPasswordIsValid: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
    error() {
      return this.$store.state.user.error;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('user/setError', '');
      this.currentPassword = undefined;
      this.newPassword = undefined;
      this.newPasswordRepeat = undefined;
      this.dialog = false;
    },
    setNewPassword() {
      this.$store
        .dispatch('user/setNewPassword', {
          current: this.currentPassword,
          updated: this.newPassword,
        })
        .then(() => {
          this.currentPassword = undefined;
          this.newPassword = undefined;
          this.newPasswordRepeat = undefined;
          this.closeDialog();
          this.success = true;
        });
    },
  },
});
</script>
