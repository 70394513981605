<template lang="pug">

    v-card(flat v-if="notificationSettings")
        v-card-title Benachrichtigungen
        v-card-subtitle {{isBusiness ? "Einstellungen, mit denen Sie festlegen, worüber Sie benachrichtigt werden möchten" : "Einstellungen, mit denen Du festlegst, worüber Du benachrichtigt werden möchtest"}}
        v-list(subheader flat)
          v-subheader Push-Benachrichtigungen
          v-list-item
            v-list-item-action
              v-tooltip(bottom :disabled="!isBlocked")
                template(v-slot:activator="{ on, attrs }" )
                  span(v-bind="attrs" v-on="on")
                    v-checkbox(color="accent" :disabled="!isAvailable || isBlocked" v-model="notificationSettings.push && notificationSettings.push.includes('matches')" @change="(e) => updatePushs(e, 'matches')")
                span Aktiviere Benachrichtigungen im Browser
            v-list-item-content
              v-list-item-title Matches
              v-list-item-subtitle {{isBusiness ? "Verpassen Sie kein neues Match" : "Verpasse kein neues Match"}}
          v-list-item(:three-line="isMobile")
            v-list-item-action
              v-tooltip(bottom :disabled="!isBlocked")
                template(v-slot:activator="{ on, attrs }")
                  span(v-bind="attrs" v-on="on")
                    v-checkbox(color="accent" :disabled="!isAvailable || isBlocked" v-model="notificationSettings.push && notificationSettings.push.includes('messages')" @change="(e) => updatePushs(e, 'messages')")
                span Aktiviere Benachrichtigungen im Browser
              
            v-list-item-content
              v-list-item-title Nachrichten
              v-list-item-subtitle {{isBusiness ? "Wir benachrichtigen Sie, wenn es neue Nachrichten gibt" : "Wir benachrichtigen Dich, wenn es neue Nachrichten gibt"}}
        
        v-divider
        v-list(subheader flat)
          v-subheader E-Mail Benachrichtigungen
          v-list-item
            v-list-item-action
              v-checkbox(color="accent" v-model="notificationSettings.email && notificationSettings.email.includes('matches')" @change="(e) => updateMails(e, 'matches')")
            v-list-item-content
              v-list-item-title Matches
              v-list-item-subtitle {{isBusiness ? "Verpassen Sie kein neues Match" : "Verpasse kein neues Match"}} 
          v-list-item(:three-line="isMobile")
            v-list-item-action
              v-checkbox(color="accent" v-model="notificationSettings.email && notificationSettings.email.includes('messages')" @change="(e) => updateMails(e, 'messages')")
            v-list-item-content
              v-list-item-title Nachrichten
              v-list-item-subtitle {{isBusiness ? "Wir benachrichtigen Sie, wenn es neue Nachrichten gibt" : "Wir benachrichtigen Dich, wenn es neue Nachrichten gibt"}}
          v-list-item(:three-line="isMobile")
            v-list-item-action
              v-checkbox(color="accent" v-model="notificationSettings.email && notificationSettings.email.includes('recommendations')" @change="(e) => updateMails(e, 'recommendations')")
            v-list-item-content
              v-list-item-title Empfehlungen
              v-list-item-subtitle {{isBusiness ? "Wir geben Ihnen Tipps für Ihre Stellen" : "Wir geben Dir Tipps für Dein Profil"}}
                

          
</template>
<script>
// import { messaging } from '@/firebase';
import { cloneDeep } from 'lodash';

export default {
  data() {
    return {
      pushStatus: false,
    };
  },
  computed: {
    isAvailable() {
      var isSafari = window.safari !== undefined;
      var NotificationIsSupported = !!(
        (
          window.Notification /* W3C Specification */ ||
          window.webkitNotifications /* old WebKit Browsers */ ||
          navigator.mozNotification
        ) /* Firefox for Android and Firefox OS */
      );
      return NotificationIsSupported && !isSafari;
    },
    notificationSettings() {
      return cloneDeep(this.$store.state.user.notificationSettings);
    },
    isEnabled() {
      const granted = window.Notification.permission === 'granted';
      return granted;
    },
    isBlocked() {
      if (!window.Notification) return false;
      const denied = window.Notification.permission === 'denied';
      return denied;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
  },
  created() {
    this.$store.dispatch('user/getNotificationSettings');
  },
  methods: {
    // async requestPermission() {
    //   // eslint-disable-next-line @typescript-eslint/no-this-alias
    //   const self = this;
    //   return Notification.requestPermission().then(() => {
    //     if (self.isEnabled()) {
    //       this.push.general = true;
    //     }
    //   });
    // },
    async updatePushs(enabled, category) {
      if (this.isAvailable) {
        const status = window.Notification.permission;
        if (status == 'default' && enabled) {
          try {
            await Notification.requestPermission();
          } catch (err) {
            if (err.code === 'messaging/token-unsubscribe-failed')
              this.updatePushs();
          }
        }
      }

      this.$store.dispatch(`user/setNotification`, {
        type: 'push',
        category,
        enabled,
      });
    },
    async updateMails(enabled, category) {
      this.$store.dispatch(`user/setNotification`, {
        type: 'email',
        category,
        enabled,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-list-item--three-line .v-list-item__content {
  align-self: baseline !important;
}
</style>
