<template lang="pug">
.settings
  v-row
    v-col.pb-2(cols='12')
      v-card(flat)
        v-card-title Konto
        v-card-subtitle(v-if='currentUser') {{ currentUser.email }}
        v-card-actions.d-flex.flex-wrap
          v-btn(text, @click='logout', color='accent') Logout
          UpdatePasswordDialog
          UpdateEmailDialog
    v-col.pb-2(cols='12' v-if="currentUser && currentUser.type === 'candidate'")
      v-card(flat)
        v-card-title Anonyme Suche
        v-card-subtitle Wenn Deine persönlichen Informationen nicht direkt für die Unternehmen sichtbar sein sollen, kannst Du hier die anonyme Suche aktivieren. Erst, wenn Du eine Nachricht an ein Unternehmen schickst, werden Deine persönlichen Details (Profilbild, Vorname, Nachname, Geburtstag, Staatsangehörigkeit, Wohnort und Geschlecht) angezeigt.
        v-list-item.pb-4
            v-list-item-action
              v-checkbox(color="accent" @change="activateAnonymous" :input-value='currentUser.anonymous')
            v-list-item-content
              v-list-item-title Anonyme Suche aktiv
        //- v-card-actions.d-flex.flex-wrap
        //-   v-checkbox(color="accent" @change="activateAnonymous" :input-value='currentUser.anonymous')
        //-   | Anonyme Suche aktiv
          
    v-col(cols='12')
      NotificationSettings
    v-col.text-center.py-8(cols="12")          
      DeleteAccountDialog
    v-col.text-center.mb-12(cols="12")          
      small.text--disabled.d-inline
        .text-capitalize.d-inline {{currentName || 'Stairship'}}  
        .text-normal.d-inline  v{{currentVersion || '0.0.1'}}

    

</template>
<script>
import Vue from 'vue';
import DeleteAccountDialog from '@/components/settings/DeleteAccountDialog';
import UpdatePasswordDialog from '@/components/settings/UpdatePasswordDialog';
import UpdateEmailDialog from '@/components/settings/UpdateEmailDialog';
import NotificationSettings from '@/components/notifications/NotificationSettings';
export default Vue.extend({
  components: {
    DeleteAccountDialog,
    UpdatePasswordDialog,
    UpdateEmailDialog,
    NotificationSettings,
  },
  data() {
    return {
      rules: {
        newPasswordRules: [
          (v) => v === this.newPassword || 'Kennwörter müssen übereinstimmen',
        ],
      },
      newPassword: undefined,
      newPasswordRepeat: undefined,
      newPasswordIsValid: false,
      currentVersion: process.env.PACKAGE_VERSION,
      currentName: process.env.PACKAGE_NAME,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
    },
    activateAnonymous(active) {
      this.$store.dispatch('user/activateAnonymous', active);
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
</style>
