<template lang="pug">
div 
    v-snackbar(v-model="success" rounded="pill" color="accent") 
      v-icon.mr-4(left color="white") mdi-check-bold
      | E-Mail-Adresse wurde erfolgreich geändert
    v-dialog(v-model='dialog' max-width="380" persistent)
        template(v-slot:activator='{ on, attrs }')
            v-btn.px-2.ml-sm-4(v-bind='attrs' v-on='on' dark text color='accent') E-Mail aktualisieren
        v-card
          v-toolbar(flat color="transparent")
            v-toolbar-title.text-h6.dialog-title.font-weight-bold E-Mail aktualisieren
            v-spacer
            v-btn(icon @click='closeDialog')
              v-icon mdi-close
          v-divider
          
          v-card-text.pt-6.px-4
            v-alert(type="error" v-if="error") {{error}}
            p {{ isBusiness ? "Um sicherzugehen, dass Sie es sind, müssen Sie Ihr aktuelles Passwort eingeben, um Ihre E-Mail zu aktualisieren." : "Um sicherzugehen, dass Du es bist, musst Du Dein aktuelles Passwort eingeben, um Deine E-Mail zu aktualisieren."}}
            v-form(v-model='validForm' :key="formKey")
              v-text-field(v-model='currentPassword' dense outlined  required='', :rules='passwordRules' label='Aktuelles Passwort' type="password")
              v-text-field(v-model='newEmail' dense outlined  label='Neue E-Mail' type="email" required='' :rules='emailRules')
          v-divider
          v-card-actions.px-4
            v-spacer
            v-btn(color='grey lighten-1' text @click='closeDialog') Abbrechen
            v-btn.white--text(color='accent darken-1'  :disabled="loading || !validForm"  depressed @click='updateEmail') Speichern
</template>
<script>
import FormRules from '@/utilities/FormRules';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      formKey: Math.random(),
      dialog: false,
      success: false,
      validForm: false,
      currentPassword: undefined,
      newEmail: undefined,
      ...FormRules,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    loading() {
      return this.$store.state.user.loading;
    },
    error() {
      return this.$store.state.user.error;
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('user/setError', '');
      this.dialog = false;
      this.formKey = Math.random();
      this.newEmail = undefined;
    },
    updateEmail() {
      this.$store
        .dispatch('user/updateEmail', {
          currentPassword: this.currentPassword,
          newEmail: this.newEmail.trim(),
          isBusiness: this.isBusiness,
        })
        .then(() => {
          this.currentPassword = undefined;
          this.newEmail = undefined;
          this.dialog = false;
          this.success = true;
        });
    },
  },
});
</script>
