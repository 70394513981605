<template lang="pug">
v-dialog(v-model='dialog' max-width="380" persistent)
        template(v-slot:activator='{ on, attrs }')
            v-btn(v-bind='attrs' v-on='on' dark text color='red') Konto löschen
        v-card
          div
            v-toolbar(flat color="transparent")
              v-toolbar-title.text-h6.dialog-title.font-weight-bold {{isBusiness ? "Sind Sie sicher?" : "Bist Du sicher?" }}
              v-spacer
              v-btn(icon @click='closeDialog')
                v-icon mdi-close
            v-divider
          v-card-text.dialog-card-text.pt-6.px-4
            v-alert.mb-4(v-if='error', color='red', dark='', dismissible='')
              span(v-if='error') {{ error }}
            p {{currentUser && currentUser.firstName ? `${currentUser.firstName},` : ''}} {{isBusiness ? "Sie sind dabei, Ihr Konto" : 'Du bist dabei, Dein Konto'}} {{currentUser && currentUser.email ? `(${currentUser.email})` : ''}} zu löschen.
            p Diese Aktion ist 
              b unwiderruflich. 
              | {{isBusiness ? "Sie verlieren alle Ihre gespeicherten Daten und verpassen neue Matches.": "Du verlierst alle Deine gespeicherten Daten und verpasst neue Matches." }}
            p {{isBusiness ? "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Dann geben Sie hier Ihr aktuelles Passwort ein:": "Bist Du sicher, dass Du Dein Konto löschen möchtest? Dann gib hier Dein aktuelles Passwort ein:" }}
            v-form(v-on:submit.prevent)
                  v-text-field(v-model='currentPassword' v-on:keydown.enter.prevent dense outlined  label='Aktuelles Passwort' type="password")
          
          v-card-actions.d-flex.flex-column.px-4
            v-btn.white--text(color='accent' block depressed @click='closeDialog') Ich werde bleiben
            v-spacer
            v-btn.my-4.white--text(color='red' :disabled="!currentPassword || currentPassword.length < 6" block text @click='deleteAccount') Jetzt löschen
</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      dialog: false,
      currentPassword: undefined,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    error() {
      return this.$store.getters['user/error'];
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('user/setError', '');
      this.dialog = false;
    },
    deleteAccount() {
      this.$store.dispatch('user/deleteAccount', this.currentPassword);
      this.currentPassword = undefined;
      this.$store.commit('user/setError', '');
    },
  },
});
</script>
